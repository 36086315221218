<template>
    <router-view />
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    device:{
      type: Object,
      default:null
    }
  }
}
</script>

<style scoped>
</style>
